import { createApp } from "vue";
import App from "./App.vue";
import * as routes  from "./routes";

// importing AOS css style globally
import "aos/dist/aos.css";
import "./app.css";
import Aos from "aos";

const app = createApp(App)

app.use(routes.router)

app.provide('state', routes.state);

app.mount("#app");

Aos.init();
