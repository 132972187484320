<template>
    <div>
        <div v-if="isLoading" class="w-full h-full bg-slate-900 fixed inset-0 z-[999]">
            <p>Loading...</p>
        </div>

        <router-view />
        <div class="fixed z-[9999] bottom-0 right-0 md:bottom-10 md:right-10">
            <img data-aos="fade-in" data-aos-delay="1000" src="@/assets/icons/we-are-here.svg" alt="" class="flex absolute -top-2 md:-top-5 -left-2 md:-left-5 z-10"/>
            <div class="w-20 h-20 md:w-28 md:h-28">
                <a href="https://wa.me/6285142623518" target="_blank" class="flex w-12 h-12 md:w-16 md:h-16 absolute top-0 left-0 z-10 justify-center items-center transition-all duration-300 bg-green-500 hover:bg-green-600 hover:cursor-pointer rounded-full shadow-lg hover:shadow-2xl mt-1.5 ml-4">
                    <Icon icon="logos:whatsapp-icon" class="text-2xl md:text-3xl"/>
                </a>
            </div>
        </div>
    </div>
</template>

<style>
    .loader {
        width: 50px;
        aspect-ratio: 1;
        display: flex;
        color: rgba(59, 129, 246, 1);
        background: linear-gradient(currentColor 0 0) right / 51% 100%, linear-gradient(currentColor 0 0) bottom / 100% 51%;
        background-repeat: no-repeat;
        animation: l16-0 1s infinite linear .25s;
    }

    .loader::before {
        content: "";
        width: 50%;
        height: 50%;
        background: currentColor;
        animation: l16-1 .5s infinite linear;
    }

    @keyframes l16-0 {

        0%,
        12.49% {
            transform: rotate(0deg)
        }

        12.5%,
        37.49% {
            transform: rotate(90deg)
        }

        37.5%,
        62.49% {
            transform: rotate(180deg)
        }

        62.5%,
        87.49% {
            transform: rotate(270deg)
        }

        87.5%,
        100% {
            transform: rotate(360deg)
        }
    }

    @keyframes l16-1 {
        0% {
            transform: perspective(80px) rotate3d(-1, -1, 0, 0)
        }
        80%,
        100% {
            transform: perspective(80px) rotate3d(-1, -1, 0, -180deg)
        }
    }
</style>

<script>
    import { Icon } from "@iconify/vue";
    import { inject } from 'vue';

    export default {
        data() {
            return {}
        },
        components: {
            Icon
        },
        methods: {},
        mounted() {},
        setup() {
            const state = inject('state');

            return {
                isLoading: state.isLoading, // Return loading state
            };
        },
    }
</script>