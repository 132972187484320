import { createRouter, createWebHistory } from 'vue-router'
import { reactive } from 'vue'

const state = reactive({
  isLoading: false,
});

const routes = [
  // {
  //   path: "/",
  //   name: "beranda",
  //   component: () =>
  //     import("../views/development/UnderConstruction.vue"),
  // },
  {
    path: "/",
    name: "beranda",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/tentang-kami",
    name: "tentang-kami",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/layanan",
    name: "layanan",
    component: () => import("../views/ServiceView.vue"),
  },
  {
    path: "/produk-unggulan",
    name: "produk-unggulan",
    component: () => import("../views/ProductView.vue")
  },
  
  // SERVICES
  {
    path: '/desain-logo',
    name: "logo",
    component: () => import("../views/services/logo/LogoView.vue"),
  },
  {
    path: '/produk/logo/:slug',
    name: "produk.logo.detail",
    component: () => import("../views/project-detail/logo/DetailProjectView.vue"),
  },

  {
    path: '/web-desain',
    name: "web-design",
    component: () => import("../views/services/web-design/WebDesignView.vue"),
  },
  {
    path: '/web-development',
    name: "web-development",
    component: () => import("../views/services/web-development/WebDevelopmentView.vue"),
  },
  {
    path: '/konten-desain',
    name: "content-design",
    component: () => import("../views/services/content-design/ContentDesignView.vue"),
  },

  // PRODUCT ROUTE
  {
    path: '/produk-unggulan/wazap',
    name: "produk.wazap.detail",
    component: () => import("../views/project-detail/webapp/wazap/DetailProjectView.vue"),
  },
  {
    path: '/produk-unggulan/eujian',
    name: "produk.eujian.detail",
    component: () => import("../views/project-detail/webapp/eujian/DetailProjectView.vue"),
  },

  // OTHER MAIN BASED ROUTE
  {
    path: "/klien",
    name: "klien",
    component: () => import("../views/ClientView.vue"),
  },
  {
    path: "/artikel",
    name: "artikel",
    component: () => import("../views/ArticleView.vue"),
  },
  {
    path: "/artikel/:slug",
    name: "artikel.detail",
    component: () => import("../views/articles/ArticleDetail.vue"),
  },

  // 
  {
    path: "/privacy-and-policy",
    name: "privacy-and-policy",
    component: () => import("../views/privacy/PrivacyAndPolicy.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: () => import("../views/privacy/TermsAndConditions.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  state.isLoading = true;
  console.log('Loading started:', state.isLoading);

  window.scrollTo(0, 0)
  
  next();
});

router.afterEach(() => {
  setTimeout(() => {
    state.isLoading = false;
    console.log('Loading ended:', state.isLoading);
  }, 3000);
});

export { router, state };